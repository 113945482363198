export const labelMotion = {
  hidden: {
    opacity: 0,
    x: -10,
  },
  visible: {
    opacity: 1,
    x: 0,
    delay: 2,
  },
}

export const labelTransition = {
  delay: 0,
  type: 'spring',
  stiffness: 120,
}
